body {
  transition: background-color, color 1s linear;

  .btn-secondary {
    --bs-btn-color: #fff !important;
  }

  &.light {
    --bs-white: #000;
    --bs-black: #F7F7F7;
    --bs-gray-100: #212529;
    --bs-gray-200: #343a40;
    --bs-gray-300: #495057;
    --bs-gray-400: #6c757d;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #ced4da;
    --bs-gray-700: #dee2e6;
    --bs-gray-800: #e9ecef;
    --bs-gray-900: #dee2e6;
    --bs-gray-950: #c7c7c7;
    --bs-body-bg: #fff;
    --bs-body-color: #000;
    --bs-header: #eee;
    --bs-input-placeholder-color: #adb5bd;
    --bs-input-group-addon-bg: #dee2e6;
    --bs-input-disabled-bg: #dee2e6;
    --bs-input-disabled-color: #343a40;
    --bs-input-color: #000;
    --bs-input-bg: #fff;
    --bs-table-bg-hover: #495057;
    --bs-table-bg-accent: #dee2e6;
    --bs-dropdown-divider-bg: #fff;
    --bs-breadcrumb-bg: #fff;
    --bs-nav-link-color: #000;

    .btn-secondary {
      --bs-btn-color: #000 !important;
    }

    .first-open {
      .aoi-buttons {
        img {
          mix-blend-mode: difference;
        }
      }
    }

    .form-control {
      color: var(--bs-input-color);
      background-color: var(--bs-input-bg);

      &:focus {
        color: var(--bs-input-color);
        background-color: var(--bs-input-bg);
      }

      &::placeholder {
        color: var(--bs-input-placeholder-color);
      }

      &:disabled {
        color: var(--bs-input-disabled-color);
        background-color: var(--bs-input-disabled-bg);
      }
    }

    .modal {
      --bs-modal-bg: #dee2e6;
      --bs-modal-content-bg: #dee2e6;
    }
  }


  .content {
    width: 100%;
    margin-top: 7rem;
    background-color: var(--bs-body-bg);
  }

  .theme-switcher {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
    max-width: 42px;
    width: 42px;
    max-height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: max-width, width 400ms ease-in-out;
    border: none;
    overflow: hidden;
    background-color: var(--bs-btn-hover-bg) !important;

    svg {
      font-size: 24px;
      color: var(--bs-white);
    }

    span {
      position: absolute;
      color: var(--bs-white);
      left: 42px;
      width: 10ch;
      font-weight: 200;
    }

    .spacer {
      flex: 1;
    }

    &:hover {
      max-width: 500px;
      width: 16ch;
    }
  }

  html, body {
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
  }

  span, p, a, button {
    font-family: "Gilroy", sans-serif;
    font-weight: 200;
    color: var(--bs-white);
  }

  .text-live {
    color: var(--bs-live);
  }

  abbr {
    font-family: "Gilroy", sans-serif;
    font-weight: 200;
    color: var(--bs-white);
  }

  abbr[title] {
    text-decoration: none;
    cursor: default;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Gilroy", sans-serif;
    font-weight: 600;
    color: var(--bs-white);
  }

  bold, strong, span.bold, a.bold {
    font-family: "Gilroy", sans-serif;
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--bs-black);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--bs-primary);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--bs-primary);
  }


  .text-right {
    text-align: right;
  }

  .main {
    padding: 2rem;
  }

  button {
    border-radius: var(--bs-btn-border-radius);
  }

  input.form-control {
    &.changed {
      border-color: var(--bs-yellow);
    }
  }

  .alert-danger {
    color: var(--bs-danger);
    border-color: var(--bs-danger);

    & > * {
      color: var(--bs-danger);
      border-color: var(--bs-danger);
    }
  }

  .logo, .logo-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 2rem;

    img {
      width: 200px;
      max-width: 50vw;
    }

    &.beta {
      background-color: var(--bs-primary);
    }

    &.stag {
      background-color: var(--bs-success);
    }

    &.dev {
      background-color: var(--bs-danger);
    }
  }

  .spacer {
    margin: 1rem 0;
  }

  .first-open {

    .aoi-buttons {

    }

    .fav-buttons {
      display: grid;
      grid-template-columns: 20% 20% 20% 20% 20%;
      max-width: 768px;

      .fav-button {
        margin: 0.125rem;
        aspect-ratio: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 50%;
        }

        &.selected {
          background-color: var(--bs-form-file-button-hover-bg);
        }
      }
    }
  }

  .picker {
    background: var(--bs-input-bg);
    color: var(--bs-input-color);
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    width: 100%;
    border: 1px solid var(--bs-input-border-color);
    position: relative;

    svg {
      position: absolute;
      right: 1rem;
      top: 0.65rem
    }

    .options {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      border: 1px solid var(--bs-input-border-color);
      border-top-width: 0;
      opacity: 0;
      border-image-outset: 1;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      margin: 0 -1px;
      max-height: 0;
      background-color: var(--bs-black);
      display: none;
      z-index: 100;
    }

    .option {
      width: 100%;
      overflow: hidden;
      padding: 0.5rem 1rem;
      color: var(--bs-input-color);
      background-color: transparent;
      border-color: transparent;
      border-top: 1px dotted var(--bs-input-border-color);
      border-radius: 0;
      transition: height, opacity 1s linear;

      &:hover {
        color: var(--bs-primary);
      }

      &.disabled {
        color: var(--bs-input-disabled-color);
        background-color: var(--bs-input-disabled-bg);
        border-color: var(--bs-input-disabled-border-color);
        cursor: default;
      }
    }

    &:hover, &:active, &:focus {
      border-color: var(--bs-primary) !important;

    }

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-width: 0;
      color: var(--bs-gray-700) !important;

      .options {
        max-height: 300rem;
        opacity: 1;
        display: block;
      }

      svg {
        top: 0.75rem
      }
    }
  }

  .btn-secondary {
    background-color: transparent;
    border-color: var(--bs-primary);
    color: var(--bs-btn-color);
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100vw;
    padding: 0 1rem;
    border-bottom: 1px solid var(--bs-gray-900);
    background-color: var(--bs-header);
    z-index: 100000;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    .logo-container {
      max-width: 370px;
      flex: 1;

      .logo {
        display: inline-block;
        margin: 1rem;
      }
    }

    .navbar {
      max-width: 1080px;
      flex: 1;
      margin: 0 auto;

      padding: 0;

      .container-fluid {
        align-items: stretch;
        justify-content: flex-start;
        height: 100%;

        a {
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          opacity: 0.8;
          padding: 0 1rem;

          &.active, &:hover, &:focus-within {
            background-color: var(--bs-gray-900);
            border-bottom-style: inset;
            border-bottom-color: var(--bs-primary);
            border-bottom-width: 0.5rem;
            opacity: 1;

            span, svg {
              color: var(--bs-primary);
            }
          }

          span {
            font-weight: 400;
            color: var(--bs-gray-300);
          }

          &:hover {
            opacity: 1;
          }

          svg {
            margin-right: 0.5rem;
            font-size: 1.5em;
            color: var(--bs-gray-300);
          }
        }
      }
    }


    .load-app {
      max-width: 370px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .navbar-toggler {
      svg {
        width: 25px;
      }
    }
  }

  .sidebar {
    background-color: var(--bs-gray-900);
    flex-direction: column;
    width: 300px;
    position: fixed;
    padding-top: 7rem;
    left: calc(100vw - 300px);
    transform: translateX(300px);
    transition: transform 0.25s linear;
    max-height: 100vh;
    height: 100vh;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--bs-gray-900);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--bs-primary);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--bs-primary);
    }

    &.show {
      transform: translateX(0);
    }

    .navbar-vertical {
      .container-fluid {
        flex-direction: column;
        align-items: stretch;

        a {
          margin: 1rem;
          padding: 0;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          cursor: pointer;

          svg {
            margin-right: 0.5rem;
            font-size: 1.5rem;
            color: var(--bs-gray-600);
            flex: 1;
          }

          span {
            flex: 6;
            font-size: 1rem;
            text-align: left;
          }

          small {
            font-size: 0.75rem;
            flex: 2;
          }
        }
      }
    }
  }

  .favorite-icon {
    background-color: transparent;
    border: none;

    &:active, &:focus {
      outline: none;
    }

    &.float-right {
      float: right;
    }
  }

  @keyframes leagueMoveIn {
    from {
      max-height: 0;
    }
    to {
      max-height: 200px;
    }
  }

  .home {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;
    width: 100vw;
    max-width: 1080px;
    margin: 0 auto;

    .leagues-small {
      flex: 0 1 30%;
      margin: 1rem;
      border: solid thin var(--bs-gray-900);
      border-radius: 1rem;

      .search-leagues {
        padding: 0.5rem 1rem;
        display: flex;
        border-bottom: solid thin var(--bs-gray-900);
        align-items: center;
        min-height: 60px;
        max-height: 60px;

        .input-group {
          input {
            padding: 0.5rem;
            background-color: transparent;
            border: none;
            color: var(--bs-white);

            &:active, &:focus {
              outline: none;
            }
          }

          .input-group-addon {
            display: none;
            visibility: hidden;
            z-index: 100;
            color: var(--bs-input-color);
            border: none;
            background-color: transparent;
          }
        }
      }

      nav {
        padding: 1rem;

        a {
          margin: 0.125rem 0;
          padding: 0.125rem 0;
          display: list-item;
          list-style: none;

          &.new {
            max-height: 0;
            animation: leagueMoveIn 0.5s ease-out;
            animation-fill-mode: forwards;
            overflow: hidden;
          }

          &.active {
            text-decoration: underline;
          }
        }

        div.nav-link {
          font-weight: bold;
          margin-top: 1rem;
        }
      }
    }

    .league-matches-container {
      flex: 0 1 70%;
      position: relative;
      margin: 1rem;
      border: solid thin var(--bs-gray-900);
      border-radius: 1rem;

      .matchdays-container {
        min-height: 60px;
        max-height: 60px;
        width: 100%;
        border-bottom: solid thin var(--bs-gray-900);
        position: relative;
        margin-bottom: 1rem;

        &:after {
          position: absolute;
          border-radius: 1rem;
          inset: 0;
          content: " ";
          background-image: linear-gradient(to right, transparent 10%, var(--bs-black) 10%, transparent 20%, transparent 80%, var(--bs-black) 90%, transparent 90%);
          pointer-events: none;
        }

        .today {
          position: absolute;
          left: 0;
          top: 0;
          width: 10%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow-x: hidden;
          user-select: none;
        }

        .matchdays {
          position: absolute;
          left: 10%;
          top: 0;
          right: 10%;
          display: flex;
          flex-direction: row;
          overflow-x: hidden;
          user-select: none;
        }

        .matchday {
          background-color: transparent;
          border: none;
          margin: 0 1rem;
          text-align: center;
        }

        .clava-calendar-container {
          position: absolute;
          right: 0;
          top: 0;
          left: 90%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          button.label {
            background: none;
            border: none;
            color: var(--bs-white);

            &:active {
              outline: none;
            }
          }

          .react-calendar-container {
            position: absolute;
            top: 0;
            right: 0;
            background-color: var(--bs-primary);
            border-radius: 1rem;
            padding: 1rem;
            z-index: 1000;

            .react-calendar {
              .react-calendar__navigation {
                display: flex;
              }

              .react-calendar__month-view__weekdays__weekday,
              .react-calendar__tile {
                padding: 0.25rem;
                position: relative;

                &.react-calendar__tile--now {
                  :after {
                    content: " ";
                    position: absolute;
                    top: 0.25rem;
                    right: 0.35rem;
                    width: 0.5rem;
                    height: 0.5rem;
                    background-color: var(--bs-live);
                    border-radius: 100%;
                  }
                }

                &.react-calendar__tile--active {
                  background-color: var(--bs-black);
                  border-radius: 100%;

                  abbr {
                    color: var(--bs-primary);
                    font-weight: 400;
                  }
                }

                &.react-calendar__month-view__days__day--neighboringMonth {
                  opacity: 0;
                  pointer-events: none;
                  cursor: default;
                }

                .disabled {
                  position: absolute;
                  inset: 0;
                  cursor: default;
                  background-color: var(--bs-black-2);
                  border-radius: 100%;
                }

                .enabled {
                  position: absolute;
                  inset: 0;
                  background-color: var(--bs-white-2);
                  border-radius: 100%;
                }
              }

              button {
                background: none;
                border: none;
                color: var(--bs-white);

                &:active {
                  outline: none;
                }
              }
            }
          }
        }
      }

      .league-matches {
        width: 100%;


        .league-match-section {
          .league-match-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0.25rem 0.5rem 0.25rem 0.5rem;
            background-color: $primary-4;

            &.link {
              cursor: pointer;
            }
          }

          .league-match-item {

            border-bottom: 1px solid var(--bs-gray-800);

            &:last-child {
              border-bottom: none;
            }

            .match-small {
              padding: 0.5rem;
              display: block;

              span {
                font-size: 125%;
              }

              .match-status, .fav-icon {
                display: flex;
                align-items: center;
                justify-content: center;

                .live {
                  color: var(--bs-live);
                }
              }
            }

            .match-big {
              background-color: var(--bs-gray-950);
              padding: 0.5rem;
              display: block;
              position: relative;
              border-bottom: 1px solid var(--bs-gray-800);

              .match-score {
                span {
                  font-size: 150%;
                }
              }

              .close-match {
                position: absolute;
                right: 0.75rem;
                top: 0.25rem;
              }
            }
          }
        }
      }

      &.small {
        flex: 0 1 20%;

        .matchdays-container {
          .today, .clava-calendar-container {
            display: none;
            visibility: hidden;
          }

          &:after {
            position: absolute;
            border-radius: 1rem;
            inset: 0;
            content: " ";
            background-image: linear-gradient(to right, transparent 2%, var(--bs-black) 2%, transparent 12%, transparent 88%, var(--bs-black) 98%, transparent 98%);
            pointer-events: none;
          }

          .matchdays {
            left: 2%;
            right: 2%;
          }
        }

        .league-matches {
          .league-match-section {
            .league-match-item {
              .match-small {
                border-radius: 0.5rem;

                > .row {
                  flex-direction: column;
                  align-items: center;

                  > * {
                    width: 90%;

                    .team-name {
                      > * {
                        &:nth-child(1) {
                          width: 100%;
                        }

                        &:nth-child(2) {
                          width: 80%
                        }

                        &:nth-child(3) {
                          width: 20%;
                        }

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                          width: 48px;
                        }
                      }

                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .news {
      flex: 1;

      .news-header {
        border-bottom: solid thin var(--bs-gray-900);
        padding: 1rem 0.5rem;
        text-align: center;
        min-height: 60px;
        max-height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        a {
          margin: 0 1rem;

          h5 {
            transition: color ease-in-out 0.25s;
          }
        }
      }

      .news-list {
        padding: 0.5rem;

        .news-list-elem {
          margin-bottom: 2rem;
          position: relative;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: center;
          border-radius: 1rem;


          &.feed {
            background-color: var(--bs-gray-900);
            padding: 0.5rem;
            border-radius: 0.5rem;

            .feed-header {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;

              .thumb {
                width: 30px;
                aspect-ratio: 1;
                border-radius: 100%;
                overflow: hidden;
                background-color: var(--bs-gray-900);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1em;
              }

              .feed-match {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .team-thumb {
                  img {
                    width: 1.5rem;
                    aspect-ratio: 1;
                  }
                }

                span {
                  margin: 0 0.25rem;
                }
              }
            }

            .medias {
              position: relative;
              display: flex;
              flex-direction: row;
              overflow: hidden;

              .media {
                flex: 0 0 100%;

                img, video {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      &.small {
        flex: 0 1 30%;
        margin-bottom: 1rem;

      }
    }
  }

  .clava-ad {
    img {
      width: 100%;
    }
  }


  .hidden {
    visibility: hidden;
    display: none;
  }

  .match-event {
    border-bottom: 1px solid var(--bs-gray-800);

    .standing {
      display: block;
      align-self: center;
      font-size: 75%;
    }

    .player, .assist {
      width: 100%;
      display: block;
    }

    .assist {
      color: var(--bs-gray-600);
      font-size: 75%;
    }

    .event-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .post {
        width: 1rem;
        height: 1rem;
        border-left: solid 2px var(--bs-gray-600);
        border-top: solid 2px var(--bs-gray-600);
        position: relative;

        .post-ball {
          color: red;
          font-size: 75%;
          position: absolute;
          top: 0;
          left: 0;
          margin-top: -0.25rem;
          margin-left: -0.25rem;
        }
      }

      .card-event {
        display: block;
        width: 0.75rem;
        height: 1rem;
        border-radius: 0.25rem;
        position: relative;
        border: 1px solid var(--bs-black);

        &.red {
          background-color: var(--bs-red);
        }

        &.yellow {
          background-color: #FEF445;
        }

        &.yellow-red {
          background-color: #FEF445;

          &:after {
            position: absolute;
            width: 0.75rem;
            height: 1rem;
            top: 0.25rem;
            border: 1px solid var(--bs-black);
            left: 0.25rem;
            content: " ";
            background-color: var(--bs-red);
            border-radius: 0.25rem;
          }
        }

        &.black {
          background-color: var(--bs-white);
        }

        &.black-red {
          background-color: var(--bs-white);

          &:after {
            position: absolute;
            width: 0.75rem;
            height: 1rem;
            top: 0.25rem;
            border: 1px solid var(--bs-black);
            left: 0.25rem;
            content: " ";
            background-color: var(--bs-red);
            border-radius: 0.25rem;
          }
        }
      }
    }
  }


  .lineup-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .field-container {
      position: relative;
      background-image: url("../assets/images/Feldrotated.png");
      background-size: cover;
      width: 100%;
      aspect-ratio: 1.46666666666;
      display: flex;
      align-items: center;
      justify-content: center;

      .team-thumb {
        position: absolute;
        opacity: 0.3;
        left: 25%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);

        &:nth-child(2) {
          left: auto;
          right: 25%;
          transform: translateY(-50%) translateX(50%);
        }
      }


      .player-in-field {
        position: absolute;
        width: 5%;

        .jersey {
          position: relative;
        }

        .player-name {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1rem;

          span {
            position: absolute;
            width: max-content;
            font-weight: 400;
            font-size: 75%;
          }
        }

        .goal, .card-event, .change {
          position: absolute;
        }

        .goal {
          right: -0.5rem;
          bottom: 0.5rem;

          svg {
            background-color: var(--bs-white);
            border-radius: 100%;
          }
        }

        .card-event {
          left: -0.5rem;
          bottom: 0.75rem;
        }

        .change {
          right: -0.5rem;
          top: -0.5rem;
        }
      }

      .card-event {
        display: block;
        width: 0.75rem;
        height: 1rem;
        border-radius: 0.25rem;
        position: relative;
        border: 1px solid var(--bs-black);

        &.red {
          background-color: var(--bs-red);
        }

        &.yellow {
          background-color: $cardYellow;
        }

        &.yellow-red {
          background-color: $cardYellow;

          &:after {
            position: absolute;
            width: 0.75rem;
            height: 1rem;
            top: 0.25rem;
            border: 1px solid var(--bs-black);
            left: 0.25rem;
            content: " ";
            background-color: var(--bs-red);
            border-radius: 0.25rem;
          }
        }
      }
    }


    .changes {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;


      > div {
        flex: 1;

        .change-event {
          border-bottom: 1px solid var(--bs-gray-600);
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .minute, .icons {
            padding: 0 1rem;

          }

          .change-player {
            text-align: start !important;
            flex: 1;
          }
        }

        &:last-child {
          .change-event {
            flex-direction: row-reverse;
          }

          .change-player {
            text-align: end !important;
          }
        }
      }
    }
  }


  .clava-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button.clava-switch-elem {
      background-color: var(--bs-black);
      border: 1px solid var(--bs-primary);
      border-left-width: 0;
      margin: 0;
      outline: none !important;
      transition: background-color ease-in-out 0.25s;

      &:first-child {
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        border-left-width: 1px;
      }

      &:last-child {
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &.selected {
        background-color: var(--bs-primary);
      }

      span {
        font-weight: 400;
      }
    }
  }

  .table-container {
    width: 100%;

    .clava-table {
      width: 100%;
      border: 1px solid var(--bs-gray-800);
      border-radius: 0.25rem;
      margin: 1rem 0;

      table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        thead {
          tr {
            border-bottom: 1px solid var(--bs-gray-800);

            th {
              button {
                background: transparent;
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                position: relative;

                span {
                  font-weight: 400;
                }

                &:after {
                  content: "";
                  position: absolute;
                  font-weight: 300;
                  font-size: 85%;
                  color: var(--bs-gray-600);
                  right: 0;
                  top: 0.25rem;
                }

                &.sort-asc {
                  &:after {
                    content: "⤓";
                    color: var(--bs-white);
                  }
                }

                &.sort-desc {
                  &:after {
                    content: "⤒";
                    color: var(--bs-white);
                  }
                }


              }

              &:nth-child(2) {
                span {
                  text-align: left;
                }
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid var(--bs-gray-800);

            &:last-child {
              border-bottom: none;
            }

            td {
              padding: 0.25rem 0;

              &:nth-child(2) {
                text-align: left;

                img {
                  margin-right: 0.25rem;
                }
              }
            }
          }
        }
      }
    }
  }


  .container {
    padding-top: 1rem;

    &.full {
      max-width: unset !important;
    }
  }

  .auth {
    border: 1px solid var(--bs-gray-900);
    border-radius: 1rem;

    .auth-header {
      border-bottom: solid thin var(--bs-gray-900);
      padding: 1rem;
      text-align: center;
      min-height: 57px;
      max-height: 57px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      span {
        margin-left: 1rem;
      }
    }

    .form {
      padding: 1rem;

      .code-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .code {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          max-width: 300px;
          width: 100%;

          input {
            aspect-ratio: 1;
            max-width: 40px;
            background-color: var(--bs-primary);
            color: var(--bs-white);
            border-radius: 0.5rem;
            border: none;
            text-align: center;
          }
        }
      }
    }
  }

  .profile {

    border: 1px solid var(--bs-gray-900);
    border-radius: 1rem;

    .profile-header {
      border-bottom: solid thin var(--bs-gray-900);
      padding: 1rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        margin: 1rem 0;
      }
    }

    .profile-content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

  }

  .default-modal {
    top: 7rem;

    .default-modal-content {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      box-shadow: 0 0 4px var(--bs-primary);

      .close {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        top: 0;
        padding: 0.5rem 0.6rem;
        aspect-ratio: 1;
        background-color: transparent;
        border: none;
        box-shadow: -1px 1px 3px var(--bs-primary);
        outline: none;
        color: var(--bs-white);
      }
    }
  }

  @keyframes rotateRefresh {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .input-group {

    .input-group-addon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 100;
      color: var(--bs-input-color);
      border: 1px solid var(--bs-input-border-color);
      aspect-ratio: 1;
      background-color: var(--bs-gray-900);


      &.reset-form {
        border-radius: 100%;
        height: 1.5rem;
        min-height: 1.5rem;
        max-height: 1.5rem;
        min-width: 1.5rem;
        max-width: 1.5rem;
        padding: 0;
        line-height: 1.5rem;
        bottom: unset;
        right: calc(calc(38px - 1.5rem) / 2);
        top: calc(calc(38px - 1.5rem) / 2);
      }

      &.transparent-pointer {
        pointer-events: none;
        cursor: pointer;

        &:nth-child(3) {
          right: 50%;
        }
      }

      &.active {
        background-color: var(--bs-primary);
      }

      &.refreshing {
        display: none;
      }

      &.disabled {
        color: var(--bs-input-disabled-color);
        border-color: var(--bs-input-disabled-border-color);
        cursor: default;
      }
    }

    &.only-focus {
      input {
        border: none;
        text-align: center;

      }

      .input-group-addon {
        display: none;
      }

      &:focus-within {
        input {
          border: initial;
          text-align: left;
        }

        .input-group-addon {
          display: flex;
        }
      }
    }

    &.searching {
      .input-group-addon.refreshing {
        display: flex;

        svg {
          animation: rotateRefresh linear 2s infinite;
        }
      }
    }

  }

  .file-preview {
    position: relative;
    width: 100%;

    img.img-preview, svg {
      width: 100%;
      font-size: 300%;
    }

    .file-progress {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: var(--bs-primary-4);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  .search-addon {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    button {
      background: none;
      border: solid thin var(--bs-gray-900);
      margin: 0.125rem 0;
      outline: none;
      text-align: left;

      &:hover {
        background: var(--bs-primary-1);
      }

      .id {
        display: inline-block;
        min-width: 50px;
      }
    }
  }

  .adminpanel {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    border: 1px solid var(--bs-gray-900);
    border-radius: 1rem;

    .adminpanel-sidebar {
      flex: 0 1 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1rem 1rem 1rem 1rem;
      border-top: solid thin var(--bs-gray-900);
      margin-top: 56px;
      position: relative;


      .navbar-toggler {
        position: absolute;
        right: 1rem;
        top: -56px;
        padding: 1rem;
      }

      &.close {
        max-width: 25px;

        a {
          display: none;
        }

        .navbar-toggler {
          right: 0.125rem;
          top: calc(-56px + 0.5rem);
          padding: 0.5rem;
        }
      }

      & > * {
        display: block;
        padding: 0.5rem;

        &.selected {
          color: var(--bs-primary);
        }
      }
    }

    .adminpanel-main {
      flex: 1 1 80%;
      border-left: solid thin var(--bs-gray-900);

      .adminpanel-header {

        border-bottom: solid thin var(--bs-gray-900);
        padding: 1rem;
        text-align: center;
        min-height: 57px;
        max-height: 57px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        span {
          margin-left: 1rem;
        }
      }

      .adminpanel-content {
        padding: 1rem;

        .form {
          transition: max-height ease-in-out 0.5s;
          padding: 0.5rem;
          border: solid thin var(--bs-gray-900);
          border-radius: 0.5rem;
          overflow: hidden;
          margin-bottom: 1rem;

          &.close {
            max-height: calc(29px + 0.5rem);
          }

          &.open {
            max-height: 100%;
          }

          &.disabled {
            opacity: 0.6;
          }

          .form-toggler {
            background: none;
            border: none;
            outline: none;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            width: 100%;

            svg {
              color: var(--bs-white);
            }
          }

          .options {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
          }
        }

        .bulk-game {
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid var(--bs-gray-900);
        }


        .adminpanel-match {
          margin-bottom: 10rem;

          .form {
            padding: 1rem;

            .match-section {
              border-radius: 0.5rem;
              background-color: var(--bs-gray-900);
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              margin-bottom: 1rem;
            }

            .match-filter {
              align-items: center;
              justify-content: center;

              .search-addon {
                position: absolute;
                background-color: var(--bs-gray-900);
                z-index: 10;
              }
            }
          }

          .input-group, input, .form-control, select {
            background-color: transparent !important;
          }

          label {
            font-size: 75%;
          }

          .bulk-table-row {
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.25rem 0;

              .match-id {
                margin-right: 1rem;
              }
            }

            &.odd {
              > div {
                background-color: var(--bs-gray-800);

                &.bulk-header {
                  background-color: var(--bs-gray-900);

                  span {
                    font-size: 75%;
                  }
                }
              }

              > div:first-child {
                background-color: transparent;
              }

              > div.bulk-header:nth-child(2) {
                border-top-left-radius: 0.5rem;
              }

              > div.bulk-header:last-child {
                border-top-right-radius: 0.5rem;
              }
            }

            &.even {
              > div {
                background-color: var(--bs-gray-700);
              }

              > div:first-child {
                background-color: transparent;
              }
            }

            .match-sorter {
              position: relative;
              padding-right: 1rem;

              .arrows {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-height: 1.5em;

                div[role="button"] {
                  flex: 1;
                  margin: 0;
                  padding: 0;
                  display: block;
                  max-height: 0.75em;

                  svg {
                    font-size: 100%;
                    line-height: 1em;
                    height: 1em;
                    vertical-align: top;
                    fill: var(--bs-white);
                  }
                }

                &.selected {

                  div[role="button"] {
                    opacity: 0;

                    &.selected {
                      color: var(--bs-primary);
                      opacity: 1;
                    }
                  }
                }
              }
            }

            input[type=number], input[type=date], input[type=time], input[type=text] {
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              font-weight: 100;

              &.changed {
                font-weight: 400;
                color: var(--bs-warning) !important;
              }
            }

            input[type=number] {
              text-align: center;
            }

            .search-addon {
              position: absolute;
              background-color: var(--bs-black);
              z-index: 10;
            }

            .mb-3 {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }


  .badges {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    button.badge-small {
      background-color: transparent;

      &.selected {
        background-color: var(--bs-primary-4);
      }
    }
  }

  .even-even-odd:nth-child(4n), .even-even-odd:nth-child(4n+1) {
    background-color: var(--bs-gray-900);
  }

  .reorder-league {
    align-items: center;
    padding: 0 1rem;
    margin: 0 1rem;
    border-left: 1px solid var(--bs-gray-900);
    border-right: 1px solid var(--bs-gray-900);
    border-top: 1px solid var(--bs-gray-900);

  }

  .reorder-league:nth-child(2n) {
    background-color: var(--bs-gray-900);
  }

  .sports-picker-container {
    padding: 0.25rem 0 0.75rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--bs-gray-900);

    > div {
      max-width: 1080px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }
  }

  .sports-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem;
  }

  .sports-picker.btn-secondary {
    overflow: hidden;
    max-width: 40px;
  }

  .sports-picker span {
    margin-left: 1rem;
  }

  .pls-load-app{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--bs-body-bg);
    border: solid thin var(--bs-primary);
    padding: 1rem;
    border-radius: 0.5rem;
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      svg{
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 1919px) {
    .form-control, .form-select {
      padding: 0.175rem 0.25rem;
      font-size: 0.75rem;
    }

    .input-group {

      .input-group-addon {


        &.reset-form {
          height: 1rem;
          min-height: 1rem;
          max-height: 1rem;
          min-width: 1rem;
          max-width: 1rem;
          padding: 0;
          line-height: .75rem;
          bottom: unset;
          right: calc(calc(26px - 1rem) / 2);
          top: calc(calc(26px - 1rem) / 2);
        }
      }
    }
  }

  @media (max-width: 768px) {

    .hidden-xl-max {
      display: none !important;
      visibility: hidden !important;
    }

    .first-open {
    /*  &:before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        width: 20%;
        height: 7%;
        border-bottom: 3rem solid transparent;
        border-left: 10rem solid transparent;
        border-top: 3rem solid var(--bs-primary);
        border-right: 10rem solid var(--bs-primary);
      } */

      .position-absolute {
        position: relative !important;
      }

      .fav-buttons {
        grid-template-columns: 20% 20% 20% 20% 20%;
      }
    }
  }

  @media (min-width: 768px) {
    .first-open {
     /* &:before {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20%;
        height: 7%;
        border-bottom: 7rem solid var(--bs-primary);
        border-left: 20rem solid var(--bs-primary);
        border-top: 7rem solid transparent;
        border-right: 20rem solid transparent;
      } */
    }
  }

  @media (max-width: 1080px) {

    .news.small, .league-matches-container.small {
      display: none;
      visibility: hidden;
    }
  }

  @media (max-width: 767px) {
    .hidden-xs {
      display: none;
      visibility: hidden;
    }
    .home {
      flex-direction: column;
      justify-content: flex-start;

      & > * {
        width: calc(100vw - 2rem);

        &.leagues-small {
          max-height: 60px;
          overflow: hidden;

          .search-leagues {
            border-bottom: none;

            .input-group {
              .input-group-addon {
                display: initial;
                visibility: visible;
              }
            }
          }

          &.open {
            max-height: 100000px;

            .search-leagues {
              border-bottom: solid thin var(--bs-gray-900);
            }
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .first-open {
      .fav-buttons {
        grid-template-columns: 25% 25% 25% 25%;
      }
    }
    .clava-table {
      table {
        thead, tbody {
          tr {
            th, td {
              &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                display: none;
                visibility: hidden;
              }
            }
          }
        }
      }
    }
    .home {
      .league-matches-container {
        .matchdays-container {
          .today {
            display: none;
            visibility: hidden;
          }

          &:after {
            position: absolute;
            border-radius: 1rem;
            inset: 0;
            content: " ";
            background-image: linear-gradient(to right, transparent 2%, var(--bs-body-bg) 2%, transparent 12%, transparent 80%, var(--bs-body-bg) 90%, transparent 90%);
            pointer-events: none;
          }

          .matchdays {
            left: 2%;
          }
        }
      }

      .match-small {
        span {
          font-size: 100%;
        }
      }
    }

    .lineup-container{

      .changes {
        > div {
          .change-event {
            .minute, .icons {
              padding: 0 0.25rem;
            }
          }
        }
      }
    }

  }

  @media (max-width: 400px) {
    * {
      font-size: 0.75rem;
    }
    .first-open {
      .fav-buttons {
        grid-template-columns: 33.33% 33.33% 33.33%;
      }
    }
    .clava-table {
      table {
        thead, tbody {
          tr {
            th, td {
              &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                display: none;
                visibility: hidden;
              }
            }
          }
        }
      }
    }

    .league-matches {
      .league-match-item {
        padding: 0;
      }
    }
  }

  @media (min-width: 768px) {

    .hidden-xl {
      display: none;
      visibility: hidden;
    }
  }

  @media (hover: none) {
    .theme-switcher {
      &:focus {
        max-width: 500px;
        width: 16ch;
      }
    }
  }

}
