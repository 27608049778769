// Bootstrap overrides
//
// Color system
//

$white: #fff !default;

$primary-100: #deecf9 !default;
$primary-200: #bdd9f3 !default;
$primary-300: #add0f0 !default;
$primary-400: #6baae4 !default;
$primary-500: #5190cb !default;
$primary-600: #3f709e !default;
$primary-700: #2d5071 !default;
$primary-800: #1b3043 !default;
$primary-900: #091016 !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-950: #1E1E1E !default;
$black: #111111 !default;
$header: #1E1E1E !default;
$black-9: rgba(0, 0, 0, 0.9) !default;
$black-8: rgba(0, 0, 0, 0.8) !default;
$black-7: rgba(0, 0, 0, 0.7) !default;
$black-6: rgba(0, 0, 0, 0.6) !default;
$black-5: rgba(0, 0, 0, 0.5) !default;
$black-4: rgba(0, 0, 0, 0.4) !default;
$black-3: rgba(0, 0, 0, 0.3) !default;
$black-2: rgba(0, 0, 0, 0.2) !default;
$black-1: rgba(0, 0, 0, 0.1) !default;
$white-9: rgba(255, 255, 255, 0.9) !default;
$white-8: rgba(255, 255, 255, 0.8) !default;
$white-7: rgba(255, 255, 255, 0.7) !default;
$white-6: rgba(255, 255, 255, 0.6) !default;
$white-5: rgba(255, 255, 255, 0.5) !default;
$white-4: rgba(255, 255, 255, 0.4) !default;
$white-3: rgba(255, 255, 255, 0.3) !default;
$white-2: rgba(255, 255, 255, 0.2) !default;
$white-1: rgba(255, 255, 255, 0.1) !default;
$primary-1: rgba(90, 160, 225, 0.1) !default;
$primary-2: rgba(90, 160, 225, 0.2) !default;
$primary-3: rgba(90, 160, 225, 0.3) !default;
$primary-4: rgba(90, 160, 225, 0.4) !default;
$primary-5: rgba(90, 160, 225, 0.5) !default;
$primary-6: rgba(90, 160, 225, 0.6) !default;
$primary-7: rgba(90, 160, 225, 0.7) !default;
$primary-8: rgba(90, 160, 225, 0.8) !default;
$primary-9: rgba(90, 160, 225, 0.9) !default;

$blue: #20a8d8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #BB0000 !default;
$orange: #FF4600 !default;
$yellow: #FF9C09 !default;
$cardYellow: #FEF445 !default;
$green: #45DF31 !default;
$teal: #20c997 !default;
$cyan: #091016 !default;
$gold: #FFD700 !default;
$silver: #C0C0C0 !default;
$bronze: #CD7F32 !default;
$live: #BB0000 !default;
$primary: #5AA0E1 !default;
$secondary: #878b8f !default;
$input-placeholder-color: $gray-500 !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.
$nav-link-color: $white !default;
$navbar-dark-color: $white !default;
$navbar-light-color: $white !default;
$navbar-toggler-focus-width: 0;
$modal-content-bg: $gray-900;
$modal-content-border-width: 0;
$body-bg: $black !default;
$body-color: $white !default;
$border-radius: 0.75rem !default;
$border-radius-sm: .5rem !default;
$border-radius-lg: 1rem !default;
$border-radius-xl: 2rem !default;
$border-radius-2xl: 4rem !default;
$border-radius-pill: 50rem !default;
$border-color: $primary !default;
// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Gilroy" !default;


// Breadcrumbs

$breadcrumb-bg: $black;
$breadcrumb-margin-bottom: 1.5rem;

// Cards

$card-border-color: $primary;
$card-cap-bg: $black;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $primary;
$dropdown-divider-bg: $black;

// Buttons

$btn-border-radius-sm: 1rem;
$btn-border-radius-lg: 2rem;
$btn-color: $white;
$link-decoration: uppercase;

// Progress bars

$progress-bg: $secondary;

// Tables

$table-bg-accent: $gray-900;
$table-bg-hover: $gray-700;

// Forms

$input-group-addon-bg: $gray-900;
$input-border-color: $primary;
$input-group-addon-border-color: $primary;
$form-check-input-border: 1px solid $primary;
$input-disabled-bg: $gray-900;
$input-disabled-color: $gray-400;
$input-disabled-border-color: $primary-6;

$colors: (
        blue: $blue,
        indigo: $indigo,
        purple: $purple,
        pink: $pink,
        red: $red,
        orange: $orange,
        yellow: $yellow,
        green: $green,
        teal: $teal,
        cyan: $cyan,
        white: $white,
        gray: $gray-600,
        gray-dark: $gray-800,
        gray-100:$gray-100,
        gray-200:$gray-200,
        gray-300:$gray-300,
        gray-400:$gray-400,
        gray-500:$gray-500,
        gray-600:$gray-600,
        gray-700:$gray-700,
        gray-800:$gray-800,
        gray-900:$gray-900,
        gray-950:$gray-950,
        header:$header,
        black:$black,
        black-9:$black-9,
        black-8:$black-8,
        black-7:$black-7,
        black-6:$black-6,
        black-5:$black-5,
        black-4:$black-4,
        black-3:$black-3,
        black-2:$black-2,
        black-1:$black-1,
        white-9:$white-9,
        white-8:$white-8,
        white-7:$white-7,
        white-6:$white-6,
        white-5:$white-5,
        white-4:$white-4,
        white-3:$white-3,
        white-2:$white-2,
        white-1:$white-1,
        input-border-color: $input-border-color,
);

$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        success: $green,
        info: $cyan,
        warning: $yellow,
        danger: $red,
        light: $gray-100,
        dark: $gray-800,
        live: $orange,
);
